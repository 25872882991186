import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class TheUnconquerableFoe extends React.Component {
    render() {
        return (
            <Layout>
                <h1>The unconquerable foe - Graham Ravey</h1>
                <div className="libraryImages">
                    <StaticImage src="../../../images/theUnconquerableFoe.jpg" alt="Sanchin"/>
                </div>

                <p>As a maturing Martial Artist, I have come to know the formidable foe known to us as Ageing. We go to
                    battle this enemy with our lotions and potions, diets and notions. All to stave off the ever
                    accelerating onslaught upon us. All of us knowing in the back of our minds that total victory can
                    never be ours, but in the true spirit of "Bushido" we must try.</p>

                <p>The word <strong>"Gambatte"</strong> springs into mind, this is Japanese for "Do your Best" and this
                    was shouted at me many times during my years of hard training at the now legendary Yoyogi Dojo,
                    Tokyo, Japan under the ever watchful eyes of Sensei Morio Higaonna. <strong>Gambatte!,
                        Gambatte!</strong> he would bark this many times filling our minds with power to push the body
                    those few extra miles. So I say to all you ageing Martial Artists out
                    there, <strong>Gambatte!</strong>, don't give up, do your best, cut down, but don't give it away.
                </p>

                <p>I am only 50 but I have seen some of my comrades "in the way" fall foul to ageing. They say "Oh I
                    can't do that anymore with as much power as I used to, or my body aches so its time for me to stop."
                    Never stop, cut down accordingly. I have seen Martial Artists stop and believe me it is not a pretty
                    sight, they seem to put on weight and age quickly. So keep training and also experiment with lotions
                    and potions, diets and notions.</p>

                <p>I myself have a lot of success with cleaning my blood electrically, ingesting liquid ozone +
                    colloidal silver and using magnetic pulsar to clean my deep lymph tissue of bacteria. Then I
                    mineralize with colloidal minerals and Celtic sea salt. I will write in enormous detail about these
                    agent and publish schematics for blood cleaner and pulsar in the future. I would like to end this
                    article on ageing with a Poem written by a poet friend of mine, Peter Harmon entitled 'Age'.</p>

                <h2>AGE</h2>
                <p>Thus stands the belted warrior oiled and ready for the fray.<br/>
                    For long has he known of this ancient foe and seen its coming day.<br/>
                    And not with charge or rush or speed does this stealthy spectre come not with gun, nor knife nor
                    shooting bow not at a gallop nor a run.</p>
                <p>It shambles in like tumble weed or sneaks in hidden amongst the grass it comes to pass in winter time
                    when polish is off t' brass,
                    It rises with you in the morning in groans and creeks and cricks following you the live long day to
                    play it's ancient tricks.</p>
                <p>Legs that once like oiled machines quick to kick, to sweep and move no longer pivot with ease, no
                    longer do as all, at last, are brought to knees.</p>
                <p>But still, wise men thus became In contemplation of this game and then with wisdom, they did know how
                    to deal with this deadly foe.</p>
                <p>They laid down plans to shape the mind in discipline, they knew their kind, could never defeat such a
                    natural force the best result, to enjoy the course.</p>

                <p>To tend the body to drive the schemes amidst the smoke of Shaolin dreams.
                    Those priests they knew and they passed it on with thoughts like oceans vast and strong Sanshin,
                    sunshine breath of life, Yin and Yang man and wife, Tai chi in the garden neath a fulsome moon,
                    dancing in the darkness, or picking out a tune, laughing with the children and trusting in their
                    heart that this a passing dream time and not the final part.<br/>
                    - Peter Harmon</p>

                <p><strong>Sensei Graham Ravey</strong></p>
            </Layout>);
    }
}

export default TheUnconquerableFoe;
